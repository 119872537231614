import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

interface ServiceCardProps {
  icon: React.ElementType;
  title: string;
  description: string;
}

export default function ServiceCard({
  icon: Icon,
  title,
  description,
}: ServiceCardProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        width: "40%",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 1px 3px rgba(0, 0, 0, 0.3)",
        borderRadius: 2,
        display: "flex",
        padding: "20px 8px 20px 15px",
        alignItems: "center",
        gap: 2,
        wordBreak: "keep-all",
        "@media (max-width:450px)": {
          width: "100%",
        },
      }}
    >
      <Icon sx={{ fontSize: 50, color: theme.palette.secondary.main }} />
      <Box>
        <Typography sx={{ fontSize: 16, mb: 0.5 }}>{title}</Typography>
        <Typography
          sx={{
            fontSize: 13,
            color: theme.palette.info.contrastText,
            fontStyle: "italic",
          }}
        >
          {description}
        </Typography>
      </Box>
    </Box>
  );
}
