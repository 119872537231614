import { Box, Button, Divider, Typography, useTheme } from "@mui/material";
import ProfilPicture from "../../../assets/profilPicture.jpeg";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MaltIcon from "../../../assets/malt-icon.svg";
import GitHubIcon from "@mui/icons-material/GitHub";
import DownloadIcon from "@mui/icons-material/Download";
import ProfilUserInfo from "../../../components/profilUserInfo.tsx/ProfilUserInfo";
import ProfilUserLink from "../../../components/profilUserLink/ProfilUserLink";
import resumeFr from "../../../assets/resume-fr.pdf";
import resumeEn from "../../../assets/resume-en.pdf";
import lang from "../../../utils/language.json";
import { useLanguage } from "../../../context/AppContext";

export default function ProfilSection() {
  const theme = useTheme();
  const { language } = useLanguage();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        padding: "0px 30px 20px 30px",
        position: "fixed",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 1px 3px rgba(0, 0, 0, 0.3)",
        borderRadius: 4,
        "@media (max-width:1390px)": {
          position: "unset",
          width: 420,
        },
        "@media (max-width:450px)": {
          position: "unset",
          width: 300,
        },
      }}
    >
      <img
        src={ProfilPicture}
        alt="profil"
        style={{ width: 175, marginTop: 40, borderRadius: 20 }}
      />
      <Typography sx={{ mt: 2, fontSize: 24, mb: 1 }}>
        {lang.general.profil.name}
      </Typography>
      <Typography
        sx={{
          backgroundColor: theme.palette.background.paper,
          padding: "10px 20px",
          borderRadius: 2,
          fontSize: 14,
          mb: 2,
        }}
      >
        {lang[language].profil.profession}
      </Typography>
      <a
        href={language === "fr" ? resumeFr : resumeEn}
        download="CV Pierre Aillet 2024"
        target="_blank"
        rel="noreferrer"
      >
        <Button
          sx={{
            color: theme.palette.secondary.main,
            borderColor: theme.palette.secondary.main,
            borderRadius: 2,
            mb: 1,
            fontFamily: "monospace",
          }}
          variant="outlined"
        >
          <DownloadIcon sx={{ color: theme.palette.secondary.main }} />
          {lang[language].profil.downloadResume}
        </Button>
      </a>
      <Divider
        sx={{
          width: "75%",
          borderColor: theme.palette.info.main,
          mt: 1,
          mb: 1,
        }}
      />
      <Box>
        <ProfilUserInfo
          icon={MailOutlineIcon}
          label={lang.general.profil.email.label}
          value={lang.general.profil.email.value}
        />
        <ProfilUserInfo
          icon={FmdGoodIcon}
          label={lang.general.profil.localisation.label}
          value={lang.general.profil.localisation.value}
        />
      </Box>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "center", gap: 2 }}>
        <ProfilUserLink
          icon={LinkedInIcon}
          link={lang.general.profil.link.linkedin}
        />
        <ProfilUserLink
          icon={LinkedInIcon}
          link={lang.general.profil.link.malt}
          isNonMuiIcon={true}
          linkNonMuiIcon={MaltIcon}
        />
        <ProfilUserLink
          icon={GitHubIcon}
          link={lang.general.profil.link.github}
        />
      </Box>
    </Box>
  );
}
