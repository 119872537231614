import {
  Alert,
  Box,
  Button,
  Snackbar,
  Stack,
  TextField,
  useTheme,
  AlertColor,
} from "@mui/material";
// import { theme } from "../../../../utils/theme";
import TitleSection from "../../../../components/titleSection/TitleSection";
import SendIcon from "@mui/icons-material/Send";
import { useState } from "react";
import lang from "../../../../utils/language.json";
import { useLanguage } from "../../../../context/AppContext";
import axios from "axios";

export default function ContactSection() {
  const { language } = useLanguage();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const [statusSnackbar, setStatusSnackBar] = useState<AlertColor>("success");
  const theme = useTheme();

  const handleCloseSnackBar = () => setOpenSnackBar(false);

  const sendEmail = () => {
    let data = JSON.stringify({
      name,
      email,
      description: message,
    });

    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: "https://rethinkr-api.com/portfolio/email",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (response.status === 200) {
          setStatusSnackBar("success");
          setOpenSnackBar(true);
        } else {
          setStatusSnackBar("error");
          setOpenSnackBar(true);
        }
      })
      .catch((error) => {
        console.log("j'ai une erreur: ", error);
        setStatusSnackBar("error");
        setOpenSnackBar(true);
      });
  };

  return (
    <Stack>
      <TitleSection title="Contact" />
      <Box sx={{ display: "flex", gap: 3 }}>
        <TextField
          label={lang[language].contact.name}
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{
            color: "white",
            mt: 2,
            width: "40%",
            "& .MuiInputBase-input": {
              color: theme.palette.primary.main, // Couleur du texte
            },
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: theme.palette.background.paper, // Couleur de la bordure normale
              },
              "&.Mui-focused fieldset": {
                borderColor: theme.palette.secondary.main, // Couleur de la bordure quand le champ est focus
              },
            },
          }}
        />
        <TextField
          label={lang[language].contact.email}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{
            mt: 2,
            width: "40%",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: theme.palette.background.paper, // Couleur de la bordure normale
              },
              "& .MuiInputBase-input": {
                color: theme.palette.primary.main, // Couleur du texte
              },
              "&.Mui-focused fieldset": {
                borderColor: theme.palette.secondary.main, // Couleur de la bordure quand le champ est focus
              },
            },
          }}
        />
      </Box>
      <TextField
        label={lang[language].contact.message}
        multiline
        rows={5}
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        sx={{
          mt: 2,
          width: "82.3%",
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              borderColor: theme.palette.background.paper, // Couleur de la bordure normale
            },
            "& .MuiInputBase-input": {
              color: theme.palette.primary.main, // Couleur du texte
            },
            "&.Mui-focused fieldset": {
              borderColor: theme.palette.secondary.main, // Couleur de la bordure quand le champ est focus
            },
          },
        }}
      />
      <Button
        startIcon={<SendIcon />}
        onClick={() => {
          sendEmail();
        }}
        sx={{
          width: 150,
          mt: 2,
          boxShadow:
            "0 4px 6px rgba(0, 0, 0, 0.5), 0 1px 3px rgba(0, 0, 0, 0.3)",
          color: theme.palette.secondary.main,
          fontWeight: 700,
        }}
      >
        {lang[language].contact.validate}
      </Button>
      <Snackbar
        open={openSnackBar}
        autoHideDuration={6000}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity={statusSnackbar}
          variant="filled"
          sx={{ width: "100%" }}
        >
          {statusSnackbar === "error"
            ? lang[language].contact.emailSendError
            : lang[language].contact.emailSendSuccess}
        </Alert>
      </Snackbar>
    </Stack>
  );
}
