import React from "react";
import "./ProfilUserLink.css";

interface ProfilUserLinkProps {
  icon: React.ElementType;
  link: string;
  isNonMuiIcon?: boolean;
  linkNonMuiIcon?: string;
}

export default function ProfilUserLink({
  icon: Icon,
  link,
  isNonMuiIcon = false,
  linkNonMuiIcon = "",
}: ProfilUserLinkProps) {
  return (
    <a href={link} target="_blank" rel="noreferrer">
      {isNonMuiIcon ? (
        <img
          src={linkNonMuiIcon}
          alt="social media icon"
          className="social-media-icon"
        />
      ) : (
        <Icon
          sx={{
            color: "white",
            "&:hover": {
              transform: "scale(1.2)",
            },
          }}
        />
      )}
    </a>
  );
}
