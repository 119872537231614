import "./App.css";
import HomePage from "./pages/home/Home";
import Header from "./components/header/Header";
import { AppProvider } from "./context/AppContext";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

function App() {
  return (
    <>
      <AppProvider>
        <Header />
        <HomePage />
        <Analytics />
        <SpeedInsights />
      </AppProvider>
    </>
  );
}

export default App;
