import { createTheme } from "@mui/material";

export const darkTheme = createTheme({
  palette: {
    primary: {
      main: "#fbfbfb",
    },
    secondary: {
      main: "#ffd438",
    },
    background: {
      default: "#282828",
      paper: "#3f3f3f",
    },
    info: {
      main: "#575757",
      light: "#6d6d6d",
      contrastText: "#d3d3d3",
    },
  },
  typography: {
    fontFamily: "Inter",
    body1: {
      color: "#fbfbfb",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: "#fbfbfb",
        },
      },
    },
  },
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 1px 3px rgba(0, 0, 0, 0.3)",
});

// Save for potential update
// export const lightTheme = createTheme({
//   palette: {
//     primary: {
//       main: "#000000",
//     },
//     secondary: {
//       main: "#ffd438",
//     },
//     background: {
//       default: "#ffffff",
//       paper: "#F4F6F8",
//     },
//     info: {
//       main: "#575757",
//       light: "#6d6d6d",
//       contrastText: "#4d4d4d",
//     },
//   },
//   typography: {
//     fontFamily: "Inter",
//     body1: {
//       color: "#000000",
//     },
//   },
//   components: {
//     MuiTab: {
//       styleOverrides: {
//         root: {
//           color: "#000000",
//         },
//       },
//     },
//   },
//   boxShadow:
//     "box-shadow: 0px 12px 24px -4px #919EAB1F; box-shadow: 0px 0px 2px 0px #919EAB33;",
// });
