import { useState, createContext, useContext, ReactNode } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { darkTheme } from "../utils/theme";

// Types pour le mode sombre/clair
// type ColorMode = "light" | "dark";

// interface ColorModeContextType {
//   colorMode: ColorMode;
//   setColorMode: (mode: ColorMode) => void;
// }

interface LanguageContextType {
  language: "en" | "fr";
  setLanguage: (language: "en" | "fr") => void;
}

// Créer les contextes
// const ColorModeContext = createContext<ColorModeContextType | undefined>(
//   undefined
// );
const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined
);

// Hook pour accéder au mode sombre/clair
// export const useColorMode = (): ColorModeContextType => {
//   const context = useContext(ColorModeContext);
//   if (!context) {
//     throw new Error("useColorMode must be used within a ColorModeProvider");
//   }
//   return context;
// };

// Hook pour accéder à la langue
export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
};

// Le Provider global pour gérer le mode sombre/clair et la langue
interface AppProviderProps {
  children: ReactNode;
}

export function AppProvider({ children }: AppProviderProps) {
  // const [colorMode, setColorMode] = useState<ColorMode>("dark");
  const [language, setLanguage] = useState<"en" | "fr">("en");

  // useEffect(() => {
  //   const body = document.body;
  //   if (colorMode === "dark") {
  //     body.classList.add("dark-theme");
  //   } else {
  //     body.classList.remove("dark-theme");
  //   }
  // }, [colorMode]);

  return (
    // <ColorModeContext.Provider value={{ colorMode, setColorMode }}>
    <LanguageContext.Provider value={{ language, setLanguage }}>
      <ThemeProvider theme={darkTheme}>{children}</ThemeProvider>
    </LanguageContext.Provider>
    // </ColorModeContext.Provider>
  );
}
