import { Box, Typography, useTheme } from "@mui/material";
import LaptopIcon from "@mui/icons-material/Laptop";
import StorageIcon from "@mui/icons-material/Storage";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import ServiceCard from "../../../../components/serviceCard/ServiceCard";
import LogoPylote from "../../../../assets/logo-clients/pylote-logo.png";
import LogoLeslyCloud from "../../../../assets/logo-clients/leslycloud-logo.png";
import TitleSection from "../../../../components/titleSection/TitleSection";
import { useLanguage } from "../../../../context/AppContext";
import lang from "../../../../utils/language.json";

export default function AboutSection() {
  const { language } = useLanguage();
  const theme = useTheme();

  return (
    <Box>
      <TitleSection title={lang[language].about.title} />
      <Typography sx={{ color: theme.palette.info.contrastText, mt: 2 }}>
        {lang[language].about.description}
      </Typography>
      <Typography sx={{ mt: 4, fontSize: 20, fontWeight: 500 }}>
        {lang[language].about.services.title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 3,
          mt: 3,
          flexWrap: "wrap",
        }}
      >
        <ServiceCard
          icon={LaptopIcon}
          title={lang[language].about.services.web.title}
          description={lang[language].about.services.web.description}
        />
        <ServiceCard
          icon={StorageIcon}
          title={lang[language].about.services.backend.title}
          description={lang[language].about.services.backend.description}
        />
        <ServiceCard
          icon={PhoneIphoneIcon}
          title={lang[language].about.services.mobile.title}
          description={lang[language].about.services.mobile.description}
        />
      </Box>
      <Typography sx={{ mt: 4, fontSize: 20, fontWeight: 500 }}>
        {lang[language].about.clients.title}
      </Typography>
      <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
        <Box
          sx={{
            backgroundColor: theme.palette.background.paper,
            display: "inline-flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "20px 50px 20px 50px",
            borderRadius: 3,
            gap: 10,
            boxShadow:
              "0 4px 6px rgba(0, 0, 0, 0.5), 0 1px 3px rgba(0, 0, 0, 0.3)",
            "@media (max-width:450px)": {
              padding: "5px 15px 5px 15px",
              gap: 2,
            },
          }}
        >
          <a
            href={lang.general.about.clients.link.pylote}
            target="_blank"
            rel="noreferrer"
          >
            <img style={{ width: 70 }} src={LogoPylote} alt="Pylote Logo" />
          </a>
          <a
            href={lang.general.about.clients.link.leslycloud}
            target="_blank"
            rel="noreferrer"
          >
            <img src={LogoLeslyCloud} alt="Pylote Logo" />
          </a>
        </Box>
      </Box>
    </Box>
  );
}
