import jsLogo from "./js-logo.svg";
import cssLogo from "./css-logo.svg";
import htmlLogo from "./html-logo.svg";
import reactLogo from "./react-logo.svg";
import redduxLogo from "./reddux-logo.png";
import tsLogo from "./ts-logo.svg";
import nodejsLogo from "./nodejs-logo.svg";
import nextLogo from "./next-logo.jpg";
import nestLogo from "./nest-logo.svg";
import postgresqlLogo from "./postgresql-logo.svg";
import githubLogo from "./github-logo.svg";
import gitlabLogo from "./gitlab-logo.svg";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  jsLogo,
  cssLogo,
  htmlLogo,
  reactLogo,
  redduxLogo,
  tsLogo,
  nodejsLogo,
  nextLogo,
  nestLogo,
  postgresqlLogo,
  githubLogo,
  gitlabLogo,
};
