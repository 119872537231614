import { Box, Tab, Tabs, useTheme } from "@mui/material";
import { useState } from "react";
import AboutSection from "./about/About";
import ResumeSection from "./resume/Resume";
import PortfolioSection from "./portfolio/Portfolio";
import BlogSection from "./blog/Blog";
import ContactSection from "./contact/Contact";
import { useLanguage } from "../../../context/AppContext";
import lang from "../../../utils/language.json";

export default function MainSection() {
  const [statusTab, setStatusTab] = useState<string>("about");
  const theme = useTheme();
  const { language } = useLanguage();

  return (
    <Box
      sx={{
        width: "65%",
        backgroundColor: theme.palette.background.default,
        padding: "0px 30px 20px 30px",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 1px 3px rgba(0, 0, 0, 0.3)",
        borderRadius: 4,
        marginLeft: 45,
        marginTop: 1,
        "@media (max-width:1390px)": {
          width: "80%",
          marginLeft: 0,
        },
        "@media (max-width:450px)": {
          marginTop: 3,
          width: 300,
          marginLeft: 0,
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Tabs
          value={statusTab}
          onChange={(event, newValue) => setStatusTab(newValue)}
          aria-label="basic tabs example"
          indicatorColor="secondary"
          textColor="secondary"
          variant="scrollable"
          scrollButtons="auto"
        >
          <Tab label={lang[language].navigation.about} value="about" />
          <Tab label={lang[language].navigation.resume} value="resume" />
          <Tab label={lang[language].navigation.portfolio} value="portfolio" />
          <Tab label={lang[language].navigation.blog} value="blog" />
          <Tab label={lang[language].navigation.contact} value="contact" />
        </Tabs>
      </Box>
      {statusTab === "about" && <AboutSection />}
      {statusTab === "resume" && <ResumeSection />}
      {statusTab === "portfolio" && <PortfolioSection />}
      {statusTab === "blog" && <BlogSection />}
      {statusTab === "contact" && <ContactSection />}
    </Box>
  );
}
