import {
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { Typography, useTheme } from "@mui/material";

interface ItemTimelineProps {
  subtitle: string;
  title: string;
  date: string;
  description: string;
}

export default function ItemTimeline({
  subtitle,
  title,
  date,
  description,
}: ItemTimelineProps) {
  const theme = useTheme();

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot color="secondary" />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <Typography sx={{ fontSize: 20, fontWeight: 800 }}>{title}</Typography>
        <Typography sx={{ fontSize: 18 }}>{subtitle}</Typography>
        <Typography
          sx={{
            color: theme.palette.secondary.main,
            fontWeight: 600,
            fontFamily: "monospace",
          }}
        >
          {date}
        </Typography>
        <Typography
          sx={{ fontSize: 14, color: theme.palette.info.contrastText }}
        >
          {description.split("\n").map((line, index) => (
            <p key={index}>{line}</p>
          ))}
        </Typography>
      </TimelineContent>
    </TimelineItem>
  );
}
