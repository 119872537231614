import { Box, MenuItem, Select, Typography } from "@mui/material";
import { useLanguage } from "../../context/AppContext";
import { useTheme } from "@mui/material/styles";
import lang from "../../utils/language.json";

export default function Header() {
  const { language, setLanguage } = useLanguage();
  const theme = useTheme();

  return (
    <Box
      sx={{
        padding: "20px 30px 0px 30px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        // position: "sticky",
        top: 0,
      }}
    >
      <Typography
        sx={{
          fontSize: 20,
          fontWeight: 600,
          letterSpacing: 2,
          textShadow: "3px 3px 6px rgba(0, 0, 0, 0.7)",
          cursor: "pointer",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {lang.general.logo}
      </Typography>
      <Box sx={{ display: "flex", gap: 3 }}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language}
          onChange={(e) => setLanguage(e.target.value === "en" ? "en" : "fr")}
          sx={{
            color: theme.palette.primary.main,
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
            "&:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: theme.palette.primary.main,
            },
            "& .MuiSelect-icon": {
              color: theme.palette.primary.main, // Flèche blanche
            },
          }}
        >
          <MenuItem value="fr">🇫🇷 {lang[language].header.lang.fr}</MenuItem>
          <MenuItem value="en">🇺🇸 {lang[language].header.lang.en}</MenuItem>
        </Select>
      </Box>
    </Box>
  );
}
