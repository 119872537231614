import { Box } from "@mui/material";
import ComingSoonGif from "../../../../assets/coming-soon.gif";
import TitleSection from "../../../../components/titleSection/TitleSection";

export default function PortfolioSection() {
  return (
    <Box>
      <TitleSection title="Portfolio" />
      <Box
        sx={{
          justifyContent: "center",
          display: "flex",
          alignItems: "center",
          height: 300,
        }}
      >
        <img src={ComingSoonGif} alt="coming soon gif" />
      </Box>
    </Box>
  );
}
