import { Box, Tooltip, Typography, useTheme } from "@mui/material";
import React from "react";

interface ProfilUserInfoProps {
  icon: React.ElementType;
  label: string;
  value: string;
}

export default function ProfilUserInfo({
  icon: Icon,
  label,
  value,
}: ProfilUserInfoProps) {
  const theme = useTheme();

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 2 }}>
      {/* Utilisation de l'icône passée en paramètre */}
      <Icon
        sx={{
          color: theme.palette.secondary.main,
          padding: 1.5,
          borderRadius: 2,
          boxShadow: theme.boxShadow,
        }}
      />
      <Box>
        <Typography
          sx={{
            fontSize: 12,
            color: theme.palette.info.light,
            fontWeight: 800,
          }}
        >
          {label}
        </Typography>
        {label === "EMAIL" ? (
          <Tooltip
            title="Copy"
            sx={{ cursor: "pointer" }}
            onClick={() => navigator.clipboard.writeText(value)}
          >
            <Typography>{value}</Typography>
          </Tooltip>
        ) : (
          <Typography>{value}</Typography>
        )}
      </Box>
    </Box>
  );
}
