import { Divider, Typography, useTheme } from "@mui/material";

interface TitleSectionProps {
  title: string;
}

export default function TitleSection({ title }: TitleSectionProps) {
  const theme = useTheme();

  return (
    <>
      <Typography sx={{ fontSize: 24, fontWeight: 800 }}>{title}</Typography>
      <Divider
        sx={{
          width: "5%",
          borderWidth: 2,
          borderColor: theme.palette.secondary.main,
          mt: 1,
          mb: 1,
        }}
      />
    </>
  );
}
