import { Box } from "@mui/material";
import ProfilSection from "../../sections/home/profilSection/ProfilSection";
import MainSection from "../../sections/home/mainSection/MainSection";

export default function HomePage() {
  return (
    <Box
      sx={{
        padding: "20px 30px 50px 30px",
        display: "flex",
        width: "100%",
        "@media (max-width:1390px)": {
          flexDirection: "column",
        },
        "@media (max-width:450px)": {
          padding: "20px 10px 50px 20px",
          flexDirection: "column",
        },
      }}
    >
      <ProfilSection />
      <MainSection />
    </Box>
  );
}
