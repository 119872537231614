import ScienceIcon from "@mui/icons-material/Science";
import SchoolIcon from "@mui/icons-material/School";
import { Timeline, timelineItemClasses } from "@mui/lab";
import { Box, Typography, useTheme } from "@mui/material";
import ItemTimeline from "../../../../components/itemTimeline/ItemTimeline";
import ItemSkill from "../../../../components/itemSkill/ItemSkill";
import TitleSection from "../../../../components/titleSection/TitleSection";
import { useLanguage } from "../../../../context/AppContext";
import lang from "../../../../utils/language.json";
import skillsPic from "../../../../assets/skills";

export default function ResumeSection() {
  const { language } = useLanguage();
  const theme = useTheme();

  return (
    <Box>
      <TitleSection title={lang[language].resume.title} />
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
        }}
        position="right"
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
          <ScienceIcon
            sx={{
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.background.paper,
              padding: "10px",
              borderRadius: 3,
            }}
          />
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
            {lang[language].resume.experiences.title}
          </Typography>
        </Box>
        {lang[language].resume.experiences.value.map((experience, index) => (
          <ItemTimeline
            key={index}
            title={experience.title}
            date={experience.date}
            subtitle={experience.profession}
            description={experience.description}
          />
        ))}
      </Timeline>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
          mt: 3,
        }}
        position="right"
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
          <SchoolIcon
            sx={{
              color: theme.palette.secondary.main,
              backgroundColor: theme.palette.background.paper,
              padding: "10px",
              borderRadius: 3,
            }}
          />
          <Typography sx={{ fontSize: 20, fontWeight: 500 }}>
            {lang[language].resume.education.title}
          </Typography>
        </Box>
        {lang[language].resume.education.value.map((education, index) => (
          <ItemTimeline
            key={index}
            title={education.title}
            date={education.date}
            subtitle={education.profession}
            description={education.description}
          />
        ))}
      </Timeline>
      <Typography sx={{ fontSize: 24, fontWeight: 800, mt: 4 }}>
        My Skills
      </Typography>
      <Box sx={{ display: "flex", mt: 2, gap: 2, flexWrap: "wrap" }}>
        {lang.general.resume.skills.map((skills, index) => (
          <ItemSkill
            name={skills.value}
            picture={skillsPic[skills.logo as keyof typeof skillsPic]}
          />
        ))}
      </Box>
    </Box>
  );
}
