import { Box, Typography, useTheme } from "@mui/material";

interface ItemSkillProps {
  name: string;
  picture: string;
}

export default function ItemSkill({ name, picture }: ItemSkillProps) {
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        backgroundColor: theme.palette.background.paper,
        alignItems: "center",
        width: 150,
        height: 40,
        gap: 2,
        padding: "10px 0px 10px 20px",
        borderRadius: 3,
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.5), 0 1px 3px rgba(0, 0, 0, 0.3)",
      }}
    >
      <img style={{ width: "38px" }} src={picture} alt="JavaScript Skills" />
      <Typography>{name}</Typography>
    </Box>
  );
}
